@import "../styles.scss";

.books_cw {
  background-color: $color__gray;
  color: $color__blue;
  display: inline;
}

.books_entry {
  border: 1px dotted #fe4a49;
  border-radius: 10px;
  margin: 20px;
  padding: 20px;
}

.books__review {
  margin-left: 20px;
}

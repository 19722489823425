@import "../styles.scss";

.gallery {
  display: flex;
  justify-content: center;
  margin: 0 10px;
  padding: 0 10px;

  img {
    width: 100%;
    max-width: 700px;
    padding: 0 5px;
  }
}

.gallery__button {
  background-color: transparent;
  border: 1px dotted $color__orange;
  height: 100%;
  padding: 10px;
  position: sticky;
  text-align: center;
  top: 50%;
}

.gallery.gallery__modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  .gallery__button {
    background-color: $color__background;
    height: fit-content;
    margin: 0 10px;
    top: 50%;
    z-index: 1;
  }
}

.gallery__modalContent {
  background-color: $color__background;
  margin: auto;
  padding: 20px;
  border: 1px solid $color__gray;
  width: 80%; /* Could be more or less, depending on screen size */

  img {
    max-width: none;
  }

  .gallery__nav {
    margin: 10px auto;
    width: fit-content;
  }
}

@media (max-width: 600px) {
  .gallery__button {
    top: 20px;
  }

  .gallery__modalContent {
    margin: auto;
    border: 1px solid $color__gray;
    padding: 0;
    width: 100%; /* Could be more or less, depending on screen size */

    img {
      max-width: none;
      padding: 0;
    }
  }
}

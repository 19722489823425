@import "../styles.scss";

.home {
  display: grid;
  grid-template-columns: repeat(10, minmax(0, 1fr));
  grid-template-rows: repeat(10, minmax(0, 1fr));
}

.home__room {
  grid-column-start: 1;
  grid-column-end: 11;
  grid-row-start: 1;
  grid-row-end: 11;
  width: 100%;
}

.home__comix {
  grid-column-start: 3;
  grid-row-start: 8;
}

.home__books {
  grid-column-start: 5;
  grid-row-start: 8;
}

.home__sleepy {
  grid-column-start: 7;
  grid-row-start: 9;
}

.home__button {
  text-decoration: none;

  span {
    display: block;
    width: 100px;
    color: transparent;
    border: none;
    border-radius: 10px;
    height: auto;
    padding: 20px 10px;
    position: relative;
    margin: 20px;
    font-weight: bold;
    text-align: center;
  }

  &:hover {
    span {
      background-color: rgba(0, 0, 0, 0.8);
      color: #fff;

      &:after {
        content: " ";
        position: absolute;
        right: 35%;
        bottom: -14px;
        border-top: 15px solid rgba(0, 0, 0, 0.8);
        border-right: 15px solid transparent;
        border-left: 15px solid transparent;
        border-bottom: none;
      }
    }
  }
}

@media (max-width: 1200px) {
  .home {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .home__comix {
    grid-column-start: 1;
    grid-row-start: 5;

    &.home__button {
      span {
        left: 10%;
      }
    }
  }

  .home__comix.home__button {
    span {
      &:after {
        content: " ";
        position: absolute;
        right: 35%;
        bottom: 34px;
        border-bottom: 15px solid rgba(0, 0, 0, 0.8);
        border-right: 15px solid transparent;
        border-left: 15px solid transparent;
        border-top: none;
      }
    }
  }

  .home__books {
    grid-column-start: 2;
    grid-row-start: 4;

    &.home__button {
      span {
        top: -15%;
        left: 20%;
      }
    }
  }

  .home__sleepy {
    grid-column-start: 3;
    grid-row-start: 5;

    &.home__button {
      span {
        top: -70%;
      }
    }
  }

  .home__button {
    span {
      background-color: rgba(0, 0, 0, 0.8);
      color: #fff;
      border-radius: 10px;
      height: auto;
      padding: 8px 3px;
      margin: auto;
      position: relative;

      &:after {
        content: " ";
        position: absolute;
        right: 35%;
        bottom: -14px;
        border-top: 15px solid rgba(0, 0, 0, 0.8);
        border-right: 15px solid transparent;
        border-left: 15px solid transparent;
        border-bottom: none;
      }
    }
  }
}

@import "../styles.scss";

.comix {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
}

.comix__header {
  font-family: "Oranienbaum", serif;
  font-size: 48px;
  font-weight: 900;
  line-height: 1.2;
}

@media (max-width: 600px) {
  .comix {
    padding: 0 32px;
  }
}

@import "../styles.scss";

.sleepyGallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
}

.sleepyGallery__header {
  font-family: "Oranienbaum", serif;
  font-size: 48px;
  font-weight: 900;
  line-height: 1.2;
}

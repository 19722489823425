@import "../styles.scss";

$navHeight: 200px;

.nav {
  box-sizing: border-box;
  color: $color__blue;
  font-family: "Epilogue", sans-serif;
  height: $navHeight;
}

.nav__header {
  border-bottom: 5px dotted $color__orange;
  display: flex;
  justify-content: space-between;
  padding: 32px 64px;
  text-align: end;
}

.nav__title {
  color: $color__blue;
  font-size: 72px;
  font-weight: 900;
  text-decoration: none;
}

.nav__subtitle {
  font-size: 40px;
  font-style: italic;
  font-weight: 100;
  margin-top: 16px;
}

.nav__hamburgerMenu {
  background-color: transparent;
  border: none;
  padding: 0;
  width: 60px;
  &:hover {
    cursor: pointer;
    opacity: 50%;
    .nav__bun--top {
      margin-bottom: 8px;
    }
  }
}

.nav__hamburgerMenu--open {
  .nav__bun--top {
    transform: rotate(-160deg);

    position: relative;
    right: 64px;
    top: 40px;
  }
}

.nav__sesame {
  color: #fff8dc;
  font-size: 18px;
  line-height: 0.5;
  pointer-events: none;
}

.nav__bun--top {
  background-color: #f7d688;
  border-radius: 32px 32px 0 0;
  height: 24px;
}

.nav__tomato {
  background-color: $color__orange;
  height: 10px;
}

.nav__lettuce {
  background-color: #9acd32;
  line-height: 1;
  text-align: center;
  text-decoration: #32a852 wavy line-through;
  height: 10px;
  &::after {
    color: transparent;
    height: 20px;
    content: "lechuga:)";
  }
}

.nav__patty {
  background-color: $color__dark;
  height: 10px;
}

.nav__bun--bottom {
  background-color: #f7d688;
  border-radius: 0 0 8px 8px;
  height: 10px;
}

.nav__menu {
  $navPadding: calc($navHeight + 8px);

  background-color: $color__gray--lighter;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 300;
  height: calc(100vh - $navPadding);
  right: 0;
  padding: 32px;
  position: absolute;
  text-align: center;
  top: $navPadding;
  width: 30vw;
  z-index: 100;

  a {
    color: inherit;

    &:hover {
      opacity: 50%;
    }
  }

  ul {
    text-align: left;
  }
  li {
    margin-bottom: 16px;
  }
}

.nav__menuTitle {
  border-bottom: 4px double $color__blue;
  font-family: "Oranienbaum", serif;
  font-size: 64px;
}

.nav__featured {
  margin-top: 16px;
  text-align: center;
}

@media (max-width: 600px) {
  .nav {
    height: auto;
  }

  .nav__header {
    padding: 12px;
  }

  .nav__title {
    font-size: 40px;
  }

  .nav__subtitle {
    font-size: 20px;
  }

  .nav__menu {
    top: auto;
    width: 100%;
  }
}

@mixin font__family {
  font-family: "Arial", "Helvetica", "sans-serif";
}

$color__background: #faf9f6;

$color__gray: #dad7ea;
$color__gray--light: #e3e3f5;
$color__gray--lighter: #f3f2f8;

$color__blue: #332df4;

$color__orange: #fe4a49;

$color__dark: #474044;
